import dynamic from "next/dynamic";
import Skeleton from "../Skeleton";
const ShowTime = dynamic(() => import("@/components/ShowTime"));
const AllImageSettings = dynamic(
  () => import("@/components/AllImageSettings"),
  { loading: () => <Skeleton type={"small-image"} height={"85"} /> }
);

const TopNewsVideoSingle = ({ item }) => {
  return (
    <div
      className="w-100"
      style={{ display: "inline-block", minHeight: "85px" }}
    >
      <AllImageSettings type={"topImage"} item={item} />{" "}
      <AllImageSettings type={"youtubeIconTwo"} />
      <h3 className="grid-title-small" style={{ cursor: "pointer" }}>
        {item.title.substring(0, 50)}...
      </h3>
      <p className="grid-small" suppressHydrationWarning={true}>
        <ShowTime time={item.published_at} />
      </p>
    </div>
  );
};
export default TopNewsVideoSingle;
